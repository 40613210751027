<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("RESELLER_PAYMENTS.RESELLER_PAYMENTS_LIST") }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addResellerPayment()"
                v-if="
                  $currentUserCan($permissions.PERM_CREATE_RESELLER_PAYMENTS)
                "
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("RESELLER_PAYMENTS.ADD_RESELLER_PAYMENT")
                }}</span>
              </base-button>

              <notification-subscription
                v-if="$currentUserCan($permissions.PERM_VIEW_RESELLER_PAYMENTS)"
                :objectType="'reseller-payments'"
                :events="{
                  CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>

        <resellerPayment-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ResellerPaymentListTable from "./partials/ResellerPaymentListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResellerPaymentListTable,
    NotificationSubscription,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {
    addResellerPayment() {
      this.$router.push({ name: "Add ResellerPayment" });
    },
  },
};
</script>
